import React from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout from '@/components/layout';
import { GatsbyResultSet, HomePageData, JourneyStep } from '@/models';
import Seo from '@/components/seo';

interface HomePageQueryRes {
  homepage: HomePageData;
  journeySteps: GatsbyResultSet<JourneyStep>;
}

const Home: React.FC<PageProps<HomePageQueryRes>> = ({ data }) => {
  const [homepageData] = React.useState<HomePageData>(data.homepage);

  return (
    <Layout expand>
      <Seo seo={homepageData.seo} />
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query HomePageQuery {
    journeySteps: allStrapiJourneyStep(filter: {}) {
      edges {
        node {
          id
          name
          stillThere
          to(locale: "fr", formatString: "DD MMMM YYYY")
          from(locale: "fr", formatString: "DD MMMM YYYY")
          location {
            country {
              code
              name
            }
            lat
            lng
            name
          }
        }
      }
    }
    articles: allStrapiArticle {
      edges {
        node {
          content
          description
          id
          image {
            alternativeText
          }
          title
          slug
        }
      }
    }
    homepage: strapiHomepage {
      seo {
        metaDescription
        metaTitle
        ogType
      }
    }
    categories: allStrapiCategory {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`;
